/**
 * Created by gschulz on 15.02.14.
 */

var EventHelper = (function() {
    
    var  _private = {};
    
    _private.instance = this;

    _private.events = {};

    /**
     *
     * @param {function} func
     * @param {string} eventKey
     * @param {Array} [arg]
     * @param {number} [delay]
     * @returns {boolean}
     */
    this.delayCall = function(func,eventKey,arg,delay){
        if(_.isUndefined(delay)){
            delay = 250;
        }
        var date = new Date();
        _private.events[eventKey] = {
            func: func,
            arguments: arg,
            executeTime: date.getTime() + delay,
            delay: delay
        };
        _private.instance.processDelayCall(eventKey);
        return true;
    };

    /**
     *
     * @param {string} eventKey
     */
    this.processDelayCall = function(eventKey){
        var date = new Date();
        if(_.isUndefined(_private.events[eventKey])){
            return;
        }
        if(date.getTime() >= _private.events[eventKey].executeTime){
            _private.events[eventKey].func.apply(
                this,_private.events[eventKey].arguments);
            _private.events[eventKey] = undefined;
            return;
        }
        setTimeout(function(){
            _private.instance.processDelayCall(eventKey);
        },_private.events[eventKey].delay);
    };

    /**
     *
     * @param {object} event
     * @returns {boolean}
     */
    this.isRealInput = function(event){
        if(event.type === 'blur' || event.type === 'paste'){
            return !_.isUndefined(event.originalEvent);
        }
        if(_.isUndefined(event.keyCode)){
            return false;
        }
        var keyCodes = new EfbHelperClass().numericKeyCodes;
        keyCodes.push(190);
        keyCodes.push(189);
        keyCodes.push(8);
        var inKeyCodes = $.inArray(event.keyCode, keyCodes) !== -1;
        if(!inKeyCodes && /^\d|,|\.|Backspace$/.test(event.key)){
            console.warn('keyCode check invalid and fixed',event);
            inKeyCodes = true;
        }
        return inKeyCodes;
    };

    this.isUserEvent = function(event){
        switch (event.type){
            case 'click':
                return event.pageX > 0 && event.pageY > 0;
                break;
            case 'blur':
                return !_.isUndefined(event.which);
                break;
            case 'keyup':
            case 'keydown':
            case 'keypress':
                return !_.isUndefined(event.keyCode);
                break;
            case 'change':
                return _.isUndefined(event.isTrigger);
                break;
        }
        return false;
    };

    this.clear = function(){
        _private.events = {};
    };
});
var EventHelperStatic = new EventHelper();